<template>
    <div class="mx-10">
    <!--
    Ocupación hospitalaria
    -->
        <v-img
            class="white--text align-top"
            height="200px"
            src="/images/HospitalSD.jpg"
        >
            <v-card-title justify-top>Ocupación hospitalaria</v-card-title>
        </v-img>
        <v-tabs
            v-model="tab"
            background-color="primary"
        >
            <v-tab class="primary white--text">CENSABLES</v-tab>
            <v-tab class="secondary white--text">NO CENSABLES</v-tab>

            <v-tabs-slider color="primary"></v-tabs-slider>
        </v-tabs>


        <v-tabs-items v-model="tab" >
            <v-tab-item class="primary lighten-5">
                <v-row class="pa-3">
                    <v-col xs="6" sm="6" md="4" lg="4" xl="4" cols="12" v-for="item in areasCen" :key="item.area">
                        <area-component
                            :datos="item"
                        />
                    </v-col>
                </v-row>
            </v-tab-item>

            <v-tab-item class="secondary lighten-5">
                <v-row class="pa-3">
                    <v-col xs="6" sm="6" md="4" lg="4" xl="4" cols="12" v-for="item in areasNoCen" :key="item.area">
                        <area-component
                            :datos="item"
                        />
                    </v-col>
                </v-row>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import areaComponent from '@/components/hospital/Area'
export default {
    components:{
        'area-component': areaComponent,
    },

    data: ()=>({
        tab:0
    }),

    methods:{
        //
    },

    computed: {
        areasCen: function(){
            return this.$store.getters['hospital/areasCenList'];
        },
        areasNoCen: function(){
            return this.$store.getters['hospital/areasNoCenList'];
        }
    }
}
</script>

<style>

</style>