<template>
    <div>
        <v-card>
            <v-card-title
                class="white--text"
                :class="censable"
            >
                Área: {{datos.areaNombre}}
                <v-spacer/>
                <v-btn icon dark @click="cargaDatos">
                    <v-icon>mdi-sync</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text
                @click="clickArea(datos)"
                v-ripple="{ class: `${censable}--text` }"
            >
                <div class="text-center">
                    <v-progress-circular
                        rotate="180"
                        size="110"
                        width="20"
                        :value="ocupacion"
                        :color="colorProgress"
                        class="my-3"
                    >
                        {{datos.usadas}} de {{datos.camasServicio}}
                    </v-progress-circular>
                </div>

                <v-list dense rounded flat nav v-if="datos.usadas > 0">
                    Pacientes por servicio:
                    <v-list-item v-for="(itm, id) in getServicios(datos.ocupacionServicio)" :key="id" class="my-1 grey lighten-3">

                        <v-list-item-content>
                            <v-list-item-title>{{itm.srv}}</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                            {{itm.total}}
                        </v-list-item-action>
                    </v-list-item>
                </v-list>

                <v-alert v-if="datos.usadas == 0" type="info" text>
                    No hay pacientes en el área
                </v-alert>

            </v-card-text>
        </v-card>
    </div>

</template>

<script>
export default {
    props:{
        datos:{
            type:Object,
            required: true
        }
    },
    created: function(){
        // {area:1, camasServicio:0, usadas:0, areaNombre:''},
        this.cargaDatos()
    },
    data:()=>({
        servicios:[]
    }),
    computed:{

        censable: function(){
            let color = ''
            if ([5, 6, 7, 8, 13, 14].indexOf(this.datos.area)>= 0) {
                color='primary'
            } else {
                color='secondary'
            }
            return color;
        },

        colorProgress: function(){
            let color = ''
            if (this.ocupacion >=100){
                color = 'red'
            } else {
                color = 'primary'
            }
            return color
        },

        ocupacion: function(){
            return (this.datos.usadas / this.datos.camasServicio) * 100;
        },
        //servicios(){
        //    return ([...new Set(this.datos.ocupacionServicio.map(srv =>srv.servicio))])
        //},

    },
    methods:{
        clickArea(i){
            this.$router.push({path:`/hospital/ocupacion/`+i.area,})
        },
        getServicios(dt){
            let servicios = []
            let srvtot = {}
            if(dt){
                let todos = this.datos.ocupacionServicio.map(srv =>srv.servicio)
                servicios = [...new Set(this.datos.ocupacionServicio.map(srv =>srv.servicio))]
                srvtot  = servicios.map((dt)=>{
                    let total = todos.filter((dd)=>dd==dt).length
                    return {srv:dt, total:total}
                })
            }
            //console.log(srvtot)

            return(srvtot)
        },

        cargaDatos(){
            let me = this;
            this.$http.get('internamientos/camasUtilizadas',{
                params: {
                    area: this.datos.area
                }
            })
            .then(function (response) {
                var dc = response.data;
                if (dc){
                    me.datos.camasServicio = dc.camasServicio
                    me.datos.usadas = dc.usadas
                    me.datos.areaNombre = dc.area
                    me.datos.ocupacionServicio = dc.ocupacionServicio
                } else {
                    console.log('error');
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        },
    },
    
}
</script>

<style>

</style>